@import "_animation.scss";
@import "_font-face.scss";
@import "_components.scss";
@import "_general.scss";
@import "_variables.scss";
@import "_popup.scss";
@import "_button.scss";

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: var(--default-font);
  font-size: var(--font-size-m);
  color: var(--default-font-color);
  background-color: var(--bg-color);
}

body::-webkit-scrollbar {
  display: none;
}

// hide scrollbar

label {
  font-family: var(--semi-bold-font);
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

#app {
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100vh;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

* {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

* {
  /* Customized all scrollbar */
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
    border-radius: 20px;
    width: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #77c3b9;
    border-radius: 20px;
  }

  .rc-virtual-list-scrollbar {
    background: rgba(lightgrey, 0.2);
    border-radius: 20px;
    width: 4px !important;
  }
  .rc-virtual-list-scrollbar-thumb {
    background: #77c3b9 !important;
    border-radius: 20px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #77c3b9 transparent;

  .rc-virtual-list-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #77c3b9 transparent;
  }
  /* For Firefox */
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

#app-main {
  min-height: 100vh;
  height: calc(100% - 1rem);
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: auto;
}

// override antd css
p {
  margin-bottom: 0;
}
