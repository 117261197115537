.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.w-fit {
  width: fit-content !important;
}

.h-100 {
  height: 100% !important;
}

.h-75 {
  height: 75% !important;
}

.h-50 {
  height: 50% !important;
}

.h-25 {
  height: 25% !important;
}

.h-fit {
  height: fit-content !important;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.no-select-text {
  // to disable selecting the text
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.pointer {
  cursor: pointer;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.m-auto {
  margin: auto;
}

.p-auto {
  padding: auto;
}

.opacity-50 {
  opacity: 0.5;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.hidden {
  visibility: hidden;
}

.shadow {
  filter: drop-shadow(0px 0px 8px var(--shadow-color));
}

// ANTD-SPIN

.ant-spin {
  background-color: #00000080;
  max-height: unset !important;

  &-dot,
  &-text {
    color: #fbfbfb;
  }
}

// ANTD-CARD

.ant-card {
  background: #10111b;
  border: 1px solid #3e4553;
  border-radius: 12px;
}

// Symbol
.symbol {
  font-family: var(--default-font);
  font-weight: 400;
  font-size: var(--font-size-s);
  line-height: 1.25rem;
  color: #a1a9b880;
}

// ANTD-AVATAR
.ant-avatar {
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 12.5rem;
}
