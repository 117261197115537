.ant-btn.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 0.375rem;
  padding: 0.625rem 0.75rem;
  gap: 0.5rem;
  font-family: var(--default-font);
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1.25rem;
  border: none;

  .wave-motion-appear {
    // disable ant design button wave effect
    display: none;
  }

  &.primary {
    color: var(--button-black-color);
    background-color: var(--primary-color);
    box-shadow: 0 0.063rem 0.063rem 0 var(--shadow-color);

    svg {
      width: 0.75rem;
      height: 0.75rem;
      color: var(--button-black-color) !important;
    }

    &:hover {
      color: var(--button-black-color);
      background-color: #77c3b9;
      box-shadow: 0 0.063rem 0.063rem 0 var(--shadow-color);
    }

    &:focus {
      color: var(--button-black-color);
      background-color: var(--primary-color);
      box-shadow: 0 0 0 0.25rem #77edca3d;
    }

    &:disabled,
    &[disabled] {
      color: #274d48;
      background-color: #3d6c66;
      box-shadow: 0 0.063rem 0.063rem 0 var(--shadow-color);

      svg {
        width: 0.75rem;
        height: 0.75rem;
        color: #274d48 !important;
      }
    }
  }

  &.secondary {
    color: var(--default-font-color);
    background-color: var(--secondary-color);
    box-shadow: 0 0 0 0.063rem #0000001a;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      color: var(--default-font-color) !important;
    }

    &:hover {
      color: var(--default-font-color);
      background-color: #3e4551;
      box-shadow: 0 0 0 0.063rem #0000001a;
    }

    &:focus {
      color: var(--default-font-color);
      background-color: var(--secondary-color);
      box-shadow: 0px 0px 0px 0.25rem #464f6026;
    }

    &:disabled,
    &[disabled] {
      color: #242c39;
      background-color: #353f50;
      box-shadow: 0 0 0 0.063rem #464f6033;

      svg {
        width: 0.75rem;
        height: 0.75rem;
        color: #242c39 !important;
      }
    }
  }

  &.secondary-transparent {
    color: var(--default-font-color);
    background-color: transparent;
    box-shadow: 0 0 0 0.063rem #0000001a;
    border: 0.063rem solid #323449;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      color: var(--default-font-color) !important;
    }

    &:hover {
      color: var(--default-font-color);
      background-color: #3e4551;
      box-shadow: 0 0 0 0.063rem #0000001a;
      border: 0.063rem solid #3e4551;
    }

    &:focus {
      color: var(--default-font-color);
      background-color: var(--secondary-color);
      box-shadow: 0px 0px 0px 0.25rem #464f6026;
      border: 0.063rem solid #3e4551;
    }

    &:disabled,
    &[disabled] {
      color: #64748b;
      background-color: #2d323b;
      box-shadow: 0 0 0 0.063rem #464f6033;
      border: 0.063rem solid #3e4551;

      svg {
        width: 0.75rem;
        height: 0.75rem;
        color: #64748b !important;
      }
    }
  }

  &.tertiary {
    color: var(--form-font-color);
    background-color: var(--secondary-color);
    box-shadow: 0 0.063rem 0.063rem 0 #0000001a;
    height: 2.25rem;
    padding: 0.625rem;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      color: var(--form-font-color) !important;
    }

    &:hover {
      color: var(--button-black-color);
      background-color: #77c3b9;
      box-shadow: 0 0.063rem 0.063rem 0 #0000001a;

      svg {
        color: var(--button-black-color) !important;
      }
    }

    &:focus {
      color: var(--default-font-color);
      background-color: var(--secondary-color);
      box-shadow: 0px 0px 0px 0.25rem #464f6026;
    }

    &:disabled,
    &[disabled] {
      color: #475569;
      background-color: #2d323b;
      border: 0.063rem solid #a1a9b8;
      box-shadow: 0 0.063rem 0.063rem 0 #0000001a;

      svg {
        width: 0.75rem;
        height: 0.75rem;
        color: #64748b !important;
      }
    }
  }

  &.danger {
    color: var(--button-font-color);
    background-color: var(--secondary-color);
    box-shadow: 0 0.063rem 0.063rem 0 #0000001a;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      color: var(--button-font-color) !important;
    }

    &:hover {
      color: var(--button-font-color);
      background-color: #e36465;
      box-shadow: 0 0.063rem 0.063rem 0 #0000001a;
    }

    &:focus {
      color: var(--button-font-color);
      background-color: #ff6f70;
      box-shadow: 0px 0px 0px 4px #f0909b66;
    }

    &:disabled,
    &[disabled] {
      color: #fcc5cb;
      background-color: #e59091;
      box-shadow: 0 0.063rem 0.063rem 0 #0000001a;
    }
  }

  &.filter {
    color: var(--default-font-color);
    background-color: transparent;
    border-color: transparent;
    gap: 4px;
    padding: 1.2rem 0.45rem !important;
    border: 1px solid transparent;
    svg {
      color: var(--default-font-color) !important;
    }

    &:hover {
      color: var(--default-font-color);
      background-color: #272835;
      border-color: var(--default-border-color);
    }
    &:focus {
      color: var(--default-font-color);
      background-color: var(--default-border-color);
      border-color: transparent;
      box-shadow: 0px 0px 0px 1px #868fa052;
    }
  }

  &.no-outline {
    color: var(--default-font-color);
    background: transparent;
    border-color: transparent;
    svg {
      color: var(--default-font-color) !important;
    }

    &:hover,
    &.active {
      color: var(--default-font-color);
      background-color: var(--default-border-color);
      border-color: transparent;
    }
  }

  &.icon {
    width: 2rem;
    height: 2rem;
    padding: 0.125rem 0.25rem;
  }

  &.full-width {
    width: 100%;
  }
}
