@font-face {
  font-family: Inter;
  src: url("../../public/fonts/InterVariable.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PoppinsRegular;
  src: url("../../public/fonts/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PoppinsMedium;
  src: url("../../public/fonts/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url("../../public/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PoppinsBold;
  src: url("../../public/fonts/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
