.ant-select-dropdown {
  background-color: var(--base-color);
  border: 1px solid var(--input-border-color) !important;

  .ant-select-item-option-content {
    white-space: pre-wrap;
  }

  .ant-select-item {
    color: #ffffff !important;
  }

  .ant-select-item-option {
    justify-content: space-between;

    &-active {
      background-color: #2b3440 !important;
    }
    &-selected {
      background-color: #77c3b933 !important;

      .ant-select-item-option-state {
        &::before {
          content: url(../../src/assets/svgs/tick.svg);
          height: 16px;
          width: 16px;
        }
        .anticon-check {
          display: none;
        }
      }
    }
  }

  // ant-select mode: multiple
  &.multiple-select-option {
    .dropdown-render-header {
      color: #a1a9b8;
      font: normal normal 400 11px var(--default-font);
      margin: 0.5rem 0 0.5rem 0.8rem;
    }

    // hide multiple selection option tick
    .ant-select-item-option-state::before {
      display: none;
    }

    .ant-checkbox-inner {
      background-color: unset;
      border-color: #a1a9b8;
    }

    .ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }

      // change checkbox tick color
      .ant-checkbox-inner::after {
        border-color: var(--base-color);
      }
    }
  }

  .ant-select-item-empty {
    margin: 0;
    padding: 0;
  }

  .dropdown-button {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: var(--base-color) !important;
    border: none;
    color: #ffffff !important;
    width: 100%;

    &:hover {
      background-color: #2b3440 !important;
    }

    &:focus {
      background-color: #77c3b933 !important;
    }
  }

  .ant-select-item-option-disabled {
    opacity: 0.3;
  }
}

.generic-record-dropdown {
  .ant-select-item-empty {
    display: none;
  }
}

// HEADER

.header-profile-dropdown {
  top: 5rem !important;

  .ant-dropdown-menu {
    background-color: var(--base-color) !important;
    border: 1px solid var(--section-border-color);
    padding: 0.625rem 0.5rem;
    display: grid;
    gap: 0.5rem;

    &-item {
      color: var(--default-font-color) !important;
      font: normal normal 500 var(--font-size-s) var(--default-font) !important;
      padding: 0 !important;

      &:hover {
        background-color: var(--default-border-color) !important;
      }
    }

    &-title-content {
      height: 30px;

      p {
        height: 100%;
        padding-top: 6px;
        padding-left: 14px;
      }
    }
  }
}

// SELECT-OPTION
.workflow-option {
  .option-name {
    color: #e5e6e6;
    font: normal normal 700 13px var(--default-font);
    margin-bottom: 0;
  }
  .option-email {
    color: #a1a9b8;
    font: normal normal 400 var(--font-size-s) var(--default-font);
  }
}

// NOTIFICATION

.ant-notification {
  right: -0.438rem !important;
  top: 2.75rem !important;

  &-notice {
    width: fit-content !important;
    display: flex;
    align-items: center;
    background-repeat: repeat-y;
    background-size: 5px;
    border-radius: 8px;
    padding-block: 16px !important;
    line-height: normal !important;

    &:before {
      height: 24px;
      width: 24px;
    }
  }
  &-notice-wrapper {
    background: #21222d !important;
  }
  &-notice-content {
    height: fit-content;
  }
  &-notice-message {
    color: #ffffff !important;
    width: max-content;
    margin-bottom: 0 !important;
    margin-inline-start: 1rem !important;
    padding-inline-end: 1.4rem !important;
    font: normal normal 400 13px var(--default-font) !important;
    font-style: italic !important;
  }
  &-notice-close {
    color: #8692a6 !important;
    top: 18px !important;
    inset-inline-end: 14px !important;
  }
  &-notice-icon {
    display: none;
  }
  // Types of notification style
  &-notice-info {
    background-image: url("https://www.colorhexa.com/77C3B9.png") !important;

    &::before {
      content: url(../../src/assets/svgs/notification-info.svg);
    }
  }
  &-notice-success {
    background-image: url("https://www.colorhexa.com/00CA92.png") !important;

    &::before {
      content: url(../../src/assets/svgs/notification-success.svg);
    }
  }
  &-notice-error {
    background-image: url("https://www.colorhexa.com/FF6F70.png") !important;

    &::before {
      content: url(../../src/assets/svgs/notification-error.svg);
    }
  }
  &-notice-warning {
    background-image: url("https://www.colorhexa.com/FFC22D.png") !important;

    &::before {
      content: url(../../src/assets/svgs/notification-warning.svg);
    }
  }
}

// DATE PICKER
.ant-picker {
  color: #a1a9b8;
  font: normal normal 400 var(--font-size-s) var(--default-font) !important;

  &.ant-picker-outlined {
    background-color: transparent;
    border-color: transparent;
  }

  .ant-picker-input > input {
    width: 7rem;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    background-color: #21222d !important;
  }

  .ant-picker-month-panel {
    width: 16.875rem;
  }

  .ant-picker-content {
    height: 13.75rem !important;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    color: #131313 !important;
  }

  .ant-picker-footer {
    display: none !important;
  }

  .ant-picker-datetime-panel-container .ant-picker-footer {
    display: block !important;
  }

  .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: var(--primary-color) !important;
  }

  .ant-btn {
    color: var(--button-black-color);
    background-color: var(--primary-color);
    box-shadow: 0 0.063rem 0.063rem 0 var(--shadow-color);

    &:hover {
      color: var(--button-black-color) !important;
      background-color: #77c3b9 !important;
      box-shadow: 0 0.063rem 0.063rem 0 var(--shadow-color) !important;
    }
  }

  .ant-picker-now-btn {
    &:hover {
      color: var(--default-font-color) !important;
      background-color: #3e4551 !important;
      box-shadow: 0 0 0 0.063rem #0000001a !important;
    }
  }
}

// TOOLTIP
.ant-tooltip {
  --antd-arrow-background-color: var(--primary-color);

  .ant-tooltip-inner {
    background-color: var(--primary-color);
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    gap: 0.5rem;
    color: #131313;
  }

  .tooltip-title {
    font: normal normal 500 var(--font-size-s) var(--default-font) !important;
    color: #a1a9b8;
  }

  .tooltip-description {
    padding-left: 1rem;
    font: normal normal 500 13px var(--default-font) 1.25rem !important;
    color: #fbfbfb;

    li {
      margin-top: 0.5rem;
    }

    ::marker {
      color: #a1a9b8;
    }
  }
}

.workflow-instruction-tooltip {
  .ant-tooltip-inner {
    background-color: #21222d;
    color: #fbfbfb;
    border: 1px solid #3e4553;
    height: fit-content;
    width: 19rem;
    font: normal normal 500 13px var(--default-font) 25.5px !important;
  }
}

// MODAL
// --> Generate Work Order Modal
.generate-wo-modal {
  .ant-modal-body {
    overflow: unset !important;
    padding-bottom: 1rem;
  }

  .table-container {
    margin-bottom: 0.5rem;
  }

  .table-header {
    padding: 1.25rem 1.5rem;
  }

  .month-year-title {
    color: #fbfbfb;
    font: normal normal 500 var(--font-size-m) var(--default-font);
    margin-bottom: 0;
  }

  .ant-form-item:has(.ant-switch) {
    background-color: unset !important;

    label {
      color: #a1a9b8 !important;
      font: normal normal 500 var(--font-size-s) var(--default-font) !important;
    }
  }

  .ant-modal-footer {
    margin-top: 0.5rem;
  }

  .ant-table-row:has(.ant-checkbox-wrapper-disabled) {
    pointer-events: none;
    opacity: 0.5;
  }
}

// TABS DROPDOWN
.ant-tabs-dropdown {
  .ant-tabs-dropdown-menu {
    background-color: #21222d !important;
  }
}
